import React from 'react';

import { SeamlessAuthLoginPage } from './SeamlessAuthLoginScreen';
import { CoursesScreen } from '@know/pages';
import { KNOWProvider, KNOWRoutes } from '@know/ui';
import Constants from 'expo-constants';

globalThis.process = {
  ...globalThis?.process,
  env: {
    ...globalThis?.process?.env,
  }
}

export default function App() {
  const hasAccessToModule = () => {
    return true;
  };

  return (
    <KNOWProvider
      isDark={true}
      hasAccessToModule={hasAccessToModule}
      customAuth={true}
    > 
      <HomePage /> 
    </KNOWProvider>
  );
}

const HomePage = () => {
  const version = Constants.expoConfig?.extra?.version || '1.0.0'
  return <SeamlessAuthLoginPage>
        <CoursesScreen />
        <KNOWRoutes version={version} />
    </SeamlessAuthLoginPage>
};
