import React, { useContext, useEffect, useState } from "react";
import {
  Center,
  VStack,
  KNOWDBContext,
  Logo,
  WithKNOWLoader,
  Text,
} from "@know/ui";

import Constant from "expo-constants";

const getConfig = () => ({
  fbConfig: {
    apiKey:
      Constant.expoConfig?.extra?.FB_API_KEY ?? "AIzaSyAxZ5sNpr2ZwlocVOyTS-KK7VrEd8yS1Mc",
    authDomain:
      Constant.expoConfig?.extra?.FB_AUTH_DOMAIN ?? "kn-uat.firebaseapp.com",
    databaseURL:
      Constant.expoConfig?.extra?.FB_DB_URL ?? "https://kn-uat.firebaseio.com",
    projectId: Constant.expoConfig?.extra?.FB_PROJECT_ID ?? "kn-uat",
    storageBucket:
      Constant.expoConfig?.extra?.FB_STORAGE_BUCKET ?? "kn-uat.appspot.com",
    messagingSenderId:
      Constant.expoConfig?.extra?.FB_MS_SENDER_ID ?? "769721757867",
    appId:
      Constant.expoConfig?.extra?.FB_APP_ID ??
      "1:769721757867:web:acf3061d162fd44c5f47ee",
    measurementId:
      Constant.expoConfig?.extra?.FB_MEASUREMENT_ID ?? "G-M8MJXJ32NG",
  },
  apiUrl: Constant.expoConfig?.extra?.API_URL,
});

const getAuthToken = async (apiUrl: string, currentToken: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    currentToken,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const searchParams = new URL(
    document.location.toString()
  ).searchParams.toString();

  const response = await fetch(
    searchParams ? `${apiUrl}?${searchParams}` : `${apiUrl}`,
    requestOptions
  );
  const results = await response.json();

  return results;
};

export const SeamlessAuthLoginPage = ({ children }) => {
  const [config, setConfig] = useState<{
    fbConfig: object;
    apiUrl: string;
  } | null>(null);
  const [token, setToken] = useState("");

  const [signInNewUser, setSignInNewUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    currentUser,
    initializedLastCacheEnv,
    currentDBApi,
    getCurrentUserAuthToken,
    isLoading: isLoadingAuth,
  } = useContext(KNOWDBContext);

  // 1. this will run first to get config
  useEffect(() => {
    if (!config && !authenticated) {
      const config = getConfig();

      const { fbConfig } = config;
      setConfig(config);
      localStorage.setItem(
        "KNOWLastLoggedInEnvironment",
        JSON.stringify({
          [fbConfig.projectId]: fbConfig,
        })
      );
      initializedLastCacheEnv();
    }
  }, [config, authenticated]);

  // 2. after config is passed
  // initializedLastCacheEnv will set currentDBApi
  // and isLoadingAuth is will false
  // then this will run to get token
  useEffect(() => {
    (async () => {
      if (currentDBApi && config && !isLoadingAuth && !signInNewUser) {
        const currentToken = await getCurrentUserAuthToken();
        const results = await getAuthToken(config.apiUrl, currentToken);

        if (results?.success) {
          const token = results?.result;
          if (token) {
            // sign out current user to make currentUser into null
            // if current user is different or if sign in for first time
            await currentDBApi.signOut();
            setToken(token);
            setSignInNewUser(true);
          } else {
            // if current user is same user
            // we just directly authenticate
            setSignInNewUser(true);
          }
        } else {
          setIsInvalid(true);
        }
      }
    })();
  }, [currentDBApi, config, isLoadingAuth, getCurrentUserAuthToken, signInNewUser]);

  // Once current user has been made into null
  // we will sign in with new user
  useEffect(() => {
    if (currentDBApi && token && !authenticated && !currentUser) {
      (async () => {
        await currentDBApi.signInWithCustomToken(token);
      })();
    }
  }, [token, currentDBApi, authenticated, currentUser]);

  // 4. After sign in currentUser will be set
  // means is authenticated
  // signInNewUser flag is used to distinguish between the case of there is already some user logged in but the requested user is different
  // in that case we won't set authenticated flag just yet until the api return results
  useEffect(() => {
    if (currentDBApi && currentUser && signInNewUser) {
      const searchParams = new URL(
        document.location.toString()
      ).searchParams
      const courseId = searchParams.get("courseId");

      setAuthenticated(true);
      setTimeout(() => {
        window.history.pushState(null, null, `/lms/launch-course/${courseId}`)
      }, 50)
    }
  }, [token, currentDBApi, signInNewUser, currentUser]);

  if (isInvalid) {
    return (
      <Center
        flex={1}
        bg="primary.600"
        width={{ base: "100%" }}
        position="relative"
      >
        <VStack space={3} alignItems="center" justifyContent="center">
          <Text fontSize={18} color={'white'} fontWeight={600}>
            URL is either invalid or expired. Please try with a different URL.
          </Text>
        </VStack>
      </Center>
    );
  }

  if (authenticated) {
    return children;
  }

  return (
    <Center
      flex={1}
      bg="primary.600"
      width={{ base: "100%" }}
      position="relative"
    >
      <VStack space={3} alignItems="center" justifyContent="center">
        <Logo size="lg" />
        <WithKNOWLoader isLoading={true} color="primary" type="horizontal" />
      </VStack>
    </Center>
  );
};
